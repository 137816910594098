import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../state";

import { getRecords } from "../actions/record";
import { getCategories } from "../actions/category";

import MainStat from "../components/MainStat";
import StatLevel from "../components/StatLevel";
import RecentActivityCard from "../components/RecentActivityCard";

const DashboardPage = () => {
  const [{ records, category }, dispatch] = useStateValue();
  const [month] = useState(moment());

  useEffect(() => {
    dispatch({
      type: "SET_VIEW",
      payload: "Dashboard"
    });
    if (!records.loaded && records.currentMonth !== month) {
      getRecords(records.currentMonth.format("MM-YYYY"), dispatch);
    }
    if (!category.loaded) {
      getCategories(dispatch);
    }
  }, [dispatch, month, records.loaded, category.loaded, records.currentMonth]);

  const getCategory = name => {
    if (category.data) {
      const cat = category.data.find(cat => cat.name === name);
      if (cat) {
        return cat;
      }
    }
    // default
    return { color: "red", icon: "apple-alt" };
  };

  return (
    <div className="container page-container">
      <MainStat month={month} records={records} />
      <div className="row mb-2">
        <div className="col-12 text-center font-gray">
          <h6>Recent Activity</h6>
        </div>
      </div>
      {records.latestActivity.map((activity, index) => (
        <RecentActivityCard
          key={index}
          activity={activity}
          category={getCategory(activity.category)}
        />
      ))}
      <div className="row mt-4 pt-4" />
      {records.groupedCat.map((cat, index) => (
        <StatLevel cat={cat} key={index} />
      ))}
    </div>
  );
};

export default DashboardPage;
