import axios from "axios";
import moment from "moment";
import { tokenConfig } from "./auth";

export const addRecord = (record, dispatch) => {
    dispatch({ type: "SET_LOADING" });
    axios
        .post("/api/datarecord", record, tokenConfig())
        .then(res => {
            var processed = {
                ...res.data,
                day: moment(res.data.date).format("MMM DD"),
                dateFormatted: moment(res.data.date).format("YYYYMMDDHHmmss"),
                createdDate: moment(res.data.created).format("YYYYMMDDHHmmss")
            };
            dispatch({
                type: "ADD_RECORD",
                payload: processed
            });
            const { category, description } = res.data;
            dispatch({ type: "UNSET_LOADING" });
            dispatch({ type: "CLEAR_ERRORS" });
            dispatch({
                type: "SET_ALERT",
                payload: {
                    msg: `Created ${description} for "${category}"`,
                    type: "success"
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "SET_ERROR",
                payload: [err.response.data, err.response.status]
            });
            dispatch({ type: "UNSET_LOADING" });
        });
};

export const getRecords = (month, dispatch) => {
    dispatch({ type: "SET_LOADING" });
    axios
        .get(
            month ? `/api/datarecord/?month=${month}` : "/api/datarecord",
            tokenConfig()
        )
        .then(res => {
            const processed = res.data.map(data => {
                data.day = moment(data.date).format("MMM DD");
                data.dateFormatted = moment(data.date).format("YYYYMMDDHHmmss");
                data.createdDate = moment(data.created).format(
                    "YYYYMMDDHHmmss"
                );
                return data;
            });
            dispatch({
                type: "DATA_LOADED",
                payload: processed
            });
            dispatch({ type: "UNSET_LOADING" });
            dispatch({ type: "CLEAR_ERRORS" });
        })
        .catch(err => {
            dispatch({
                type: "SET_ERROR",
                payload: [err.response.data, err.response.status]
            });
            dispatch({ type: "UNSET_LOADING" });
        });
};
