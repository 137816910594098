import React, { useState } from 'react';
import { useStateValue } from '../state';
import { register } from '../actions/auth';

const RegisterForm = () => {
	const [ , dispatch ] = useStateValue();
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ fname, setFname ] = useState('');
	const [ lname, setLname ] = useState('');
	const [ email, setEmail ] = useState('');

	const registerUser = (e) => {
		e.preventDefault();
		const user = {
			username,
			password,
			fname,
			lname,
			email
		};
		register(user, dispatch);
	};

	return (
		<div className="row mt-5">
			<div className="col-8 offset-2">
				<form onSubmit={(e) => registerUser(e)}>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							id="username"
							placeholder="Enter username"
							required
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<input
							type="password"
							className="form-control"
							id="password"
							placeholder="Enter password"
							required
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<input
							type="email"
							className="form-control"
							id="email"
							placeholder="Enter Email"
							required
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							id="fname"
							placeholder="Enter First Name"
							required
							value={fname}
							onChange={(e) => setFname(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							id="lname"
							placeholder="Enter Last Name"
							required
							value={lname}
							onChange={(e) => setLname(e.target.value)}
						/>
					</div>
					<button type="submit" className="btn btn-success">
						Register
					</button>
				</form>
			</div>
		</div>
	);
};

export default RegisterForm;
