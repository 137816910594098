import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CategoryTiles = ({
	categories,
	setVariables,
	showType = false,
	hasDefault = false,
	catType = '',
	filter = false,
	clearSelection = false
}) => {
	const [ category, setCategory ] = useState(hasDefault && categories.length > 0 ? categories[0].name : '');
	const [ finalCategory, setFinalCategory ] = useState([]);
	const toggleCategory = (cat) => {
		setCategory(cat.name);
		if (setVariables) {
			setVariables(cat);
		}
	};

	useEffect(
		() => {
			setCategory('');
		},
		[ clearSelection ]
	);

	useEffect(
		() => {
			if (filter) {
				const filteredCat = categories.filter((cat) => {
					return cat.catType === catType;
				});
				setFinalCategory(filteredCat);
			} else {
				setFinalCategory(categories);
			}
		},
		[ categories, filter, catType ]
	);

	return (
		<div className="cat d-flex justify-content-sm-center mt-3">
			{finalCategory.map((cat) => (
				<div
					key={cat.name}
					onClick={() => toggleCategory(cat)}
					className={`cat-tile d-flex flex-column justify-content-center align-items-center bg-${cat.color}-light ${category ===
					cat.name
						? 'cat-tile__active'
						: ''}`}
				>
					<div className={`cat-tile__icon text-2 font-${cat.color}`}>
						<FontAwesomeIcon icon={cat.icon} />
					</div>
					<div className={`cat-tile__name font-${cat.color}`}>{cat.name}</div>
					{showType && <div className={`cat-tile__name font-${cat.color}`}>{cat.catType}</div>}
				</div>
			))}
		</div>
	);
};

export default CategoryTiles;
