export const formatMoney = (money) => {
	return numberWithCommas(toTwoDecimal(money));
};

export const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const reduceTotals = (values, type, element) => {
	const total = values
		.filter((item) => {
			return item[type] === element;
		})
		.reduce((total, value) => {
			return total + value.amount;
		}, 0);

	return Number(total.toFixed(2));
};

export const toTwoDecimal = (value) => {
	return Number(value.toFixed(2));
};

export const groupByElementSum = (array, element, aggregate, attributes = []) => {
	var result = [];
	array.reduce((res, value) => {
		if (
			result.filter((item) => {
				return item[element] === value[element];
			}).length < 1
		) {
			var obj = {
				[element]: value[element],
				total: value[aggregate]
			};
			for (const x in attributes) {
				obj[attributes[x]] = value[attributes[x]];
			}
			result.push(obj);
		} else {
			result.map((item) => {
				if (item[element] === value[element]) {
					item.total += value[aggregate];
				}
				return item;
			});
		}

		return res;
	}, {});

	return result;
};

export const groupByElementElement = (array, element, attributes = []) => {
	var result = [];
	array.reduce((res, value) => {
		if (
			result.filter((item) => {
				return item[element] === value[element];
			}).length < 1
		) {
			var obj = {
				[element]: value[element],
				data: [ value ]
			};
			for (const x in attributes) {
				obj[attributes[x]] = value[attributes[x]];
			}
			result.push(obj);
		} else {
			result.map((item) => {
				if (item[element] === value[element]) {
					item.data.push(value);
				}
				return item;
			});
		}

		return res;
	}, {});

	return result;
};

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1);
};
