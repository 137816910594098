const commonReducer = (state, action) => {
	switch (action.type) {
		case 'SET_VIEW':
			return {
				...state,
				currentView: action.payload
			};
		case 'SET_LOADING':
			return {
				...state,
				isLoading: true
			};
		case 'UNSET_LOADING':
			return {
				...state,
				isLoading: false
			};
		case 'SET_ERROR':
			return {
				...state,
				errorMsg: action.payload
			};
		case 'TURN_OFF_ALERT':
			return {
				...state,
				alertOn: false,
				alertMsg: '',
				alertType: ''
			};
		case 'SET_ALERT':
			return {
				...state,
				alertOn: true,
				alertMsg: action.payload.msg,
				alertType: action.payload.type
			};
		default:
			return state;
	}
};

export default commonReducer;
