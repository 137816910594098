const authReducer = (state, action) => {
	switch (action.type) {
		case 'USER_LOADED':
			return {
				...state,
				...action.payload,
				id: action.payload._id,
				isAuthenticated: true
			};
		case 'LOGIN_SUCCESS':
			return {
				...state,
				isAuthenticated: true,
				...action.payload.user
			};
		case 'REGISTER_SUCCESS':
			return {
				...state,
				isAuthenticated: true,
				...action.payload.user
			};
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				isAuthenticated: false,
				username: null,
				fname: null,
				lname: null
			};
		default:
			return state;
	}
};

export default authReducer;
