import axios from 'axios';

// Setup config, headers, token
export const tokenConfig = () => {
	// Get token from localStorage
	const token = localStorage.getItem('ft-token') || 'empty';

	const config = {
		headers: {
			'Content-type': 'application/json'
		}
	};
	if (token) {
		config.headers['x-auth-token'] = token;
	}

	return config;
};

export const loadUser = (dispatch) => {
	dispatch({ type: 'SET_LOADING' });
	axios
		.get('/api/auth/user', tokenConfig())
		.then((res) => {
			if (res.data) {
				dispatch({
					type: 'USER_LOADED',
					payload: res.data
				});
			}
			dispatch({ type: 'UNSET_LOADING' });
			dispatch({ type: 'CLEAR_ERRORS' });
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
		});
};

export const login = ({ username, password }, dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	// Request body
	const body = JSON.stringify({ username, password });
	dispatch({ type: 'SET_LOADING' });

	axios
		.post('/api/auth', body, config)
		.then((res) => {
			localStorage.setItem('ft-token', res.data.token);
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: res.data
			});
			dispatch({ type: 'CLEAR_ERRORS' });
			dispatch({ type: 'UNSET_LOADING' });
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
			dispatch({
				type: 'SET_ALERT',
				payload: {
					msg: err.response.data.msg,
					type: 'danger'
				}
			});
		});
};

export const register = (user, dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	// Request body
	const body = JSON.stringify(user);
	dispatch({ type: 'SET_LOADING' });

	axios
		.post('/api/users', body, config)
		.then((res) => {
			localStorage.setItem('ft-token', res.data.token);
			dispatch({
				type: 'REGISTER_SUCCESS',
				payload: res.data
			});
			dispatch({ type: 'CLEAR_ERRORS' });
			dispatch({ type: 'UNSET_LOADING' });
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
		});
};

export const logout = (dispatch) => {
	localStorage.removeItem('ft-token');
	dispatch({
		type: 'LOGOUT_SUCCESS'
	});
};
