import React, { useState, useEffect } from 'react';
import { useStateValue } from '../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../common/HelperFunc';

const StatLevel = ({ cat }) => {
	const [ { category, records } ] = useStateValue();
	const [ catInfo, setCatInfo ] = useState({ color: 'red', icon: 'apple-alt', level: 100 });

	useEffect(
		() => {
			const info = category.data.find((baseCat) => baseCat.name === cat.category);
			const divisor = cat.catType === 'Expense' ? records.largestTotalCat : records.largestTotalIn;
			const finalInfo = {
				color: info ? info.color : 'red',
				icon: info ? info.icon : 'apple-alt',
				level: cat.total / divisor * 100
			};
			setCatInfo(finalInfo);
		},
		[ category, cat, records.largestTotalCat, records.largestTotalIn ]
	);

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-10 offset-1">
					<div className="progress-bar__container">
						<div
							className={`progress-bar__level progress-bar__level-${catInfo.color}`}
							style={{ width: `${catInfo.level}%` }}
						/>
					</div>
				</div>
			</div>
			<div className="row py-2">
				<div className="col-10 offset-1 d-flex font-gray-light justify-content-between">
					<div className="cat-info__container d-flex">
						<div className="cat-logo">
							<FontAwesomeIcon icon={catInfo.icon} />
						</div>
						<div className="cat-name pl-1">{cat.category}</div>
					</div>
					<div className="cat-amount-container d-flex align-items-center">
						<div className="cat-amount">Php {formatMoney(cat.total)}</div>
						<FontAwesomeIcon
							className={`pb-1 font-${cat.catType === 'Expense' ? 'red' : 'green'}-light`}
							icon={cat.catType === 'Expense' ? 'arrow-circle-up' : 'arrow-circle-down'}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default StatLevel;
