import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatMoney } from "../common/HelperFunc";

const RecentTransactionRow = ({ data, category, selectedID, selectItem }) => {
    return (
        <div
            className="row datarecent__row p-2 d-flex align-items-center justify-content-between"
            onClick={() => selectItem(data._id)}
        >
            <div
                className={`datarecent__icon p-0 bg-${
                    category.color
                }-light font-${category.color} rounded col-2 ${
                    data._id === selectedID
                        ? "datarecent__selected"
                        : "d-flex justify-content-center align-items-center"
                }`}
            >
                <FontAwesomeIcon icon={category.icon} />
            </div>
            <div className="col-6 p-0 pl-2 d-flex flex-column justify-content-center">
                <span>{data.category}</span>
                <span className="text--75 font-gray">{data.description}</span>
            </div>
            <div className="col-4 p-0 d-flex flex-column justify-content-center align-items-end">
                <span>Php {formatMoney(data.amount)}</span>
                <span className="text--75 font-gray">
                    {moment(data.date).format("MMM DD")}
                </span>
            </div>
            <div
                className={`datarecent__close ml-2 p-0 bg-red-light font-red rounded col-2 ${
                    data._id === selectedID ? "datarecent__selected" : "d-none"
                }`}
            >
                <FontAwesomeIcon icon="window-close" />
            </div>
        </div>
    );
};

export default RecentTransactionRow;
