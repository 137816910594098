import React, { useState } from "react";
import RecentTransactionRow from "./RecentTransactionRow";

const RecentTransaction = ({ records, category }) => {
    const [selectedID, setSelectedID] = useState(null);

    const getCategory = name => {
        if (category.data) {
            const cat = category.data.find(cat => cat.name === name);
            if (cat) {
                return cat;
            }
        }
        // default
        return { color: "red", icon: "apple-alt" };
    };

    const selectItem = id => {
        id === selectedID ? setSelectedID(null) : setSelectedID(id);
    };

    return (
        <div className="row mt-3">
            <div className="col-12 col-sm-10 offset-sm-1">
                {records.groupedFromNow.map((record, index) => (
                    <React.Fragment>
                        <div className="font-gray text-1-25" key={index}>
                            {record.fromNow}
                        </div>
                        <div className="datarecent__container mb-3 shadow-sm bg-gray-100 p-2">
                            <div className="container">
                                {record.data.map(data => (
                                    <RecentTransactionRow
                                        data={data}
                                        key={data._id}
                                        category={getCategory(data.category)}
                                        selectItem={selectItem}
                                        selectedID={selectedID}
                                    />
                                ))}
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default RecentTransaction;
