import React from 'react';
import { useStateValue } from '../state';

const Header = () => {
	const [ { common } ] = useStateValue();

	return (
		<React.Fragment>
			<nav className="navbar py-4 navbar-expand-lg navbar-light bg-white d-flex justify-content-around justify-content-sm-start">
				<div className="page-title w-auto">
					<h5 className="text-center m-0 text-1-5">{common.currentView}</h5>
				</div>
			</nav>
		</React.Fragment>
	);
};

export default Header;
