import React from 'react';
import { useStateValue } from '../state';

const Loader = () => {
	const [ { common } ] = useStateValue();

	return (
		<div className={'loader ' + (common.isLoading ? '' : 'd-none')}>
			<div className="spinner-border text-success" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default Loader;
