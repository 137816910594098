import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useStateValue } from '../state';

const Alerter = () => {
	const [ { common }, dispatch ] = useStateValue();
	const [ visible, setVisible ] = useState(false);
	const [ type, setType ] = useState('success');

	useEffect(
		() => {
			if (common.alertOn) {
				setVisible(true);
				setType(common.alertType);
				setTimeout(() => {
					setVisible(false);
					dispatch({
						type: 'TURN_OFF_ALERT'
					});
				}, 3000);
			}
		},
		[ dispatch, common ]
	);

	useEffect(
		() => {
			if (visible) {
				window.scrollTo(0, 0);
			}
		},
		[ visible ]
	);

	return (
		<Alert className="p-4" color={type} isOpen={visible}>
			{common.alertMsg}
		</Alert>
	);
};

export default Alerter;
