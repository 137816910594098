import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavRudder = () => {
	const [ active, setActive ] = useState(false);
	const [ { auth } ] = useStateValue();
	const history = useHistory();
	const activeClass = 'rudder-activated rounded-pill d-flex justify-content-around';

	const navigate = (route) => {
		setActive(false);
		history.push(route);
	};

	if (!auth.isAuthenticated) {
		return null;
	}

	return (
		<div className="container nav-rudder">
			<div className="nav-rudder__container text-1-5 d-flex justify-content-center">
				<div className={`nav-rudder__main ${active && activeClass} bg-teal font-white`}>
					<FontAwesomeIcon
						icon="home"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/')}
					/>
					<FontAwesomeIcon
						icon="credit-card"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/expense')}
					/>
					<FontAwesomeIcon
						icon="money-bill"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/income')}
					/>
					<FontAwesomeIcon
						className={`nav-rudder__toggler ${active ? 'rudder-activated' : ''}`}
						icon="plus"
						onClick={() => setActive(!active)}
					/>
					<FontAwesomeIcon
						icon="chart-pie"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/data')}
					/>
					<FontAwesomeIcon
						icon="sign-out-alt"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/logout')}
					/>
					<FontAwesomeIcon
						icon="cogs"
						className={`${active ? '' : 'd-none'}`}
						onClick={() => navigate('/settings')}
					/>
				</div>
			</div>
		</div>
	);
};

export default NavRudder;
