import React, { useEffect } from 'react';
import { useStateValue } from '../state';
import { logout } from '../actions/auth';

const LogoutPage = () => {
	const [ , dispatch ] = useStateValue();
	useEffect(
		() => {
			logout(dispatch);
		},
		[ dispatch ]
	);
	return <div>Logged out</div>;
};

export default LogoutPage;
