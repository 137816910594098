import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatMoney } from "../common/HelperFunc";

const RecentActivityCard = ({ activity, category }) => {
  console.log(category);
  return (
    <div className={`row font-${category.color} mb-2 recent`}>
      <div
        className={`offset-1 p-2 col-2 d-flex justify-content-center align-items-center bg-${
          category.color
        }-light recent__logo`}
      >
        <FontAwesomeIcon icon={category.icon} />
      </div>
      <div
        className={`col-4 p-2 bg-${
          category.color
        }-light recent__desc d-flex flex-column justify-content-center`}
      >
        <div className="desc">{activity.description}</div>
        <div className="date text--75">
          {moment(activity.date).format("MMMM DD")}
        </div>
      </div>
      <div
        className={`col-4 p-2 bg-${
          category.color
        }-light d-flex justify-content-end align-items-center recent__amount`}
      >
        <div className="amount pr-2">Php {formatMoney(activity.amount)}</div>
        <FontAwesomeIcon
          className={`pb-1 font-${
            activity.catType === "Expense" ? "red" : "green"
          }-light`}
          icon={
            activity.catType === "Expense"
              ? "arrow-circle-up"
              : "arrow-circle-down"
          }
        />
      </div>
    </div>
  );
};

export default RecentActivityCard;
