import axios from 'axios';
import { tokenConfig } from './auth';

export const getCategories = (dispatch) => {
	dispatch({ type: 'SET_LOADING' });
	axios
		.get('/api/categories', tokenConfig())
		.then((res) => {
			dispatch({
				type: 'CATEGORY_LOADED',
				payload: res.data
			});
			dispatch({ type: 'UNSET_LOADING' });
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
		});
};

export const addCategory = (category, dispatch) => {
	dispatch({ type: 'SET_LOADING' });
	axios
		.post('/api/categories', { ...category }, tokenConfig())
		.then((res) => {
			dispatch({
				type: 'CATEGORY_ADDED',
				payload: res.data
			});
			dispatch({ type: 'UNSET_LOADING' });
			dispatch({
				type: 'SET_ALERT',
				payload: {
					msg: `Created ${category.name} for ${category.catType}`,
					type: 'success'
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
		});
};

export const deleteCategory = (id, dispatch) => {
	dispatch({ type: 'SET_LOADING' });
	axios
		.delete(`/api/categories/${id}`, tokenConfig())
		.then((res) => {
			dispatch({ type: 'UNSET_LOADING' });
			dispatch({
				type: 'DELETE_CATEGORY',
				payload: id
			});
			dispatch({
				type: 'SET_ALERT',
				payload: {
					msg: `Category deleted`,
					type: 'success'
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'SET_ERROR',
				payload: [ err.response.data, err.response.status ]
			});
			dispatch({ type: 'UNSET_LOADING' });
		});
};
