import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStateValue } from '../state';
import { loadUser } from '../actions/auth';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	const [ state, dispatch ] = useStateValue();

	useEffect(
		() => {
			loadUser(dispatch);
		},
		[ dispatch ]
	);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (state.common.isLoading && !state.auth.isAuthenticated) {
					return (
						<div className="d-flex justify-content-center">
							<div className="spinner-grow text-danger" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<div className="spinner-grow text-danger" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<h3>Checking credentials</h3>
						</div>
					);
				} else if (state.auth.isAuthenticated) {
					return <Component {...props} />;
				} else {
					return <Redirect to="/login" />;
				}
			}}
		/>
	);
};

export default PrivateRoute;
