import authReducer from './authReducer';
import commonReducer from './commonReducer';
import recordsReducer from './recordsReducer';
import categoryReducer from './categoryReducer';

const mainReducer = ({ auth, common, records, category }, action) => ({
	auth: authReducer(auth, action),
	common: commonReducer(common, action),
	records: recordsReducer(records, action),
	category: categoryReducer(category, action)
});

export default mainReducer;
