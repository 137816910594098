import React, { useEffect } from 'react';
import { useStateValue } from '../state';

import RecordForm from '../components/RecordForm';
import { getCategories } from '../actions/category';

const ExpensePage = () => {
	const [ { category }, dispatch ] = useStateValue();

	useEffect(
		() => {
			dispatch({
				type: 'SET_VIEW',
				payload: 'Expense'
			});
			if (!category.loaded) {
				getCategories(dispatch);
			}
		},
		[ dispatch, category ]
	);

	return (
		<div className="container page-container">
			<RecordForm categories={category.data} catType="Expense" />
		</div>
	);
};

export default ExpensePage;
