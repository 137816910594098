import React, { useState } from 'react';
import CategoryTiles from './CategoryTiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStateValue } from '../state';
import { addRecord } from '../actions/record';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RecordForm = ({ categories, catType }) => {
	const [ { auth }, dispatch ] = useStateValue();
	const [ category, setCategory ] = useState('');
	const [ color, setColor ] = useState('');
	const [ date, setDate ] = useState(new Date());
	const [ description, setDescription ] = useState('');
	const [ amount, setAmount ] = useState('');

	const setVariables = (cat) => {
		setCategory(cat.name);
		setColor(cat.color);
	};

	const submitRecord = (e) => {
		e.preventDefault();
		if (category && (description && amount)) {
			const object = {
				date,
				amount,
				category,
				description,
				catType,
				user: auth.id
			};
			addRecord(object, dispatch);
		} else {
			dispatch({
				type: 'SET_ALERT',
				payload: {
					msg: 'Please fill up all fields!',
					type: 'danger'
				}
			});
		}
	};

	const resetForm = (e) => {
		e.preventDefault();
		setDescription('');
		setAmount('');
		setDate(new Date());
	};

	return (
		<React.Fragment>
			<CategoryTiles categories={categories} setVariables={setVariables} catType={catType} filter={true} />
			<div className="record-form mt-5">
				<div className="row">
					<div className="col-10 offset-1">
						<form onSubmit={(e) => submitRecord(e)}>
							<div className="form-group mb-4">
								<div className={`font-${color} text-1-5 amount-overlay`}>₱</div>
								<input
									type="number"
									className={`amount-input form-control font-${color}-dark pl-5 p-4 rounded-pill bg-${color}-light border-color-${color}-light ph-${color}`}
									id="password"
									placeholder="Enter amount"
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									required
								/>
							</div>
							<div className="form-group mb-4">
								<div className={`font-${color} text-1-5 amount-overlay`}>
									<FontAwesomeIcon icon="calendar-alt" />
								</div>
								<DatePicker
									className={`form-control font-${color}-dark pl-5 p-4 w-100 rounded-pill bg-${color}-light border-color-${color}-light ph-${color}`}
									selected={date}
									onChange={(date) => setDate(date)}
									placeholderText="Select Date"
								/>
							</div>
							<div className="form-group">
								<textarea
									placeholder="Enter Description"
									name="desc"
									className={`ph-${color} desc-form w-100 form-control font-${color}-dark p-4 w-100 bg-${color}-light border-color-${color}-light`}
									cols="30"
									rows="5"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className="form-cta container mt-5">
				<div className="row">
					<div className="col-5 offset-1">
						<button
							onClick={(e) => submitRecord(e)}
							className="btn btn-outline-success rounded-pill btn-block"
						>
							Save
						</button>
					</div>
					<div className="col-5">
						<button onClick={(e) => resetForm(e)} className="btn btn-outline-danger rounded-pill btn-block">
							Reset
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default RecordForm;
