import React, { useState, useEffect } from 'react';
import { capitalize } from '../common/HelperFunc';
import { useStateValue } from '../state';
import { addCategory } from '../actions/category';

const CategoryForm = () => {
	const [ { category }, dispatch ] = useStateValue();
	const [ catType, setCatType ] = useState('Expense');
	const [ name, setName ] = useState('');
	const [ color, setColor ] = useState('');
	const [ icon, setIcon ] = useState('');

	const submitCategory = (e) => {
		e.preventDefault();
		const cat = {
			catType,
			name,
			color,
			icon
		};
		addCategory(cat, dispatch);
	};

	useEffect(
		() => {
			setName('');
			setColor('');
			setIcon('');
		},
		[ dispatch, category ]
	);

	return (
		<React.Fragment>
			<div className="row mb-3 mt-3">
				<div className="col-12 d-flex justify-content-center">
					<h3 className="font-gray">Manage Categories</h3>
				</div>
			</div>
			<div className="row">
				<div className="col-10 offset-1">
					<form onSubmit={(e) => submitCategory(e)}>
						<div className="form-group">
							<label className="font-gray" htmlFor="type">
								Category Type
							</label>
							<select
								className="form-control rounded-pill"
								name="type"
								value={catType}
								onChange={(e) => setCatType(e.target.value)}
								required
							>
								<option value="Expense">Expense</option>
								<option value="Income">Income</option>
							</select>
						</div>
						<div className="form-group">
							<label className="font-gray" htmlFor="name">
								Category Name
							</label>
							<input
								placeholder="Enter Category Name"
								type="text"
								className="form-control rounded-pill p-4"
								name="name"
								required
								value={name}
								onChange={(e) => setName(capitalize(e.target.value))}
							/>
						</div>
						<div className="form-group">
							<label className="font-gray" htmlFor="color">
								Category Color
							</label>
							<input
								placeholder="Enter Category Color"
								type="text"
								className="form-control rounded-pill p-4"
								name="color"
								required
								value={color}
								onChange={(e) => setColor(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label className="font-gray" htmlFor="icon">
								Category Icon
							</label>
							<input
								placeholder="Enter Category Icon"
								type="text"
								className="form-control rounded-pill p-4"
								name="icon"
								required
								value={icon}
								onChange={(e) => setIcon(e.target.value)}
							/>
						</div>
						<button type="submit" className="btn rounded-pill btn-success btn-block">
							Create
						</button>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CategoryForm;
