import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './main.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faBars,
	faArrowCircleDown,
	faArrowCircleUp,
	faAppleAlt,
	faLightbulb,
	faHome,
	faMoneyBill,
	faGasPump,
	faShoppingBasket,
	faUtensils,
	faBaby,
	faCar,
	faAlignJustify,
	faPlane,
	faDesktop,
	faGamepad,
	faMoneyCheckAlt,
	faClinicMedical,
	faParking,
	faSlidersH,
	faChartPie,
	faSave,
	faArchive,
	faBookmark,
	faCreditCard,
	faCogs,
	faSignOutAlt,
	faWindowClose,
	faCalendarAlt,
	faShoppingCart,
	faBeer,
	faBreadSlice,
	faDatabase,
	faHeartbeat,
	faPlus,
	faEdit
} from '@fortawesome/free-solid-svg-icons';

import { StateProvider } from './state';
import initialState from './reducers/initialState';
import mainReducer from './reducers/mainReducer';

import PrivateRoute from './common/PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import DataPage from './pages/DataPage';
import LoginPage from './pages/LoginPage';
import ExpensePage from './pages/ExpensePage';
import IncomePage from './pages/IncomePage';
import SettingsPage from './pages/SettingsPage';
import LogoutPage from './pages/LogoutPage';

import Header from './common/Header';
import Loader from './common/Loader';
import Alerter from './common/Alerter';
import NavRudder from './common/NavRudder';

library.add(
	faBars,
	faArrowCircleDown,
	faArrowCircleUp,
	faAppleAlt,
	faLightbulb,
	faHome,
	faMoneyBill,
	faGasPump,
	faShoppingBasket,
	faUtensils,
	faBaby,
	faCar,
	faAlignJustify,
	faPlane,
	faDesktop,
	faGamepad,
	faMoneyCheckAlt,
	faClinicMedical,
	faParking,
	faSlidersH,
	faChartPie,
	faSave,
	faArchive,
	faBookmark,
	faCreditCard,
	faCogs,
	faSignOutAlt,
	faWindowClose,
	faCalendarAlt,
	faShoppingCart,
	faBeer,
	faBreadSlice,
	faDatabase,
	faHeartbeat,
	faPlus,
	faEdit
);

function App() {
	return (
		<StateProvider initialState={initialState} reducer={mainReducer}>
			<Router>
				<Loader />
				<Header />
				<Alerter />
				<Switch>
					<PrivateRoute exact path="/" component={DashboardPage} />
					<PrivateRoute exact path="/expense" component={ExpensePage} />
					<PrivateRoute exact path="/income" component={IncomePage} />
					<PrivateRoute exact path="/data" component={DataPage} />
					<PrivateRoute exact path="/settings" component={SettingsPage} />
					<PrivateRoute exact path="/logout" component={LogoutPage} />
					<Route exact path="/login" component={LoginPage} />
				</Switch>
				<NavRudder />
			</Router>
		</StateProvider>
	);
}

export default App;
