import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useStateValue } from '../state';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

const LoginPage = () => {
	const [ { auth }, dispatch ] = useStateValue();
	const [ view, setView ] = useState('Login');

	useEffect(
		() => {
			dispatch({
				type: 'SET_VIEW',
				payload: view
			});
			dispatch({
				type: 'UNSET_LOADING'
			});
		},
		[ view, dispatch ]
	);

	if (auth.isAuthenticated) {
		return <Redirect to="/" />;
	}

	return (
		<div className="container page-container">
			<div className="row mb-5 mt-2">
				<div className="col-12 d-flex justify-content-center">
					<h3 className="font-gray">Finance Tracker</h3>
				</div>
				<div className="col-12 d-flex justify-content-center">
					<span className="font-gray-light">Neslie Project</span>
				</div>
			</div>
			<ul className="nav nav-pills nav-fill d-flex justify-content-around">
				<li className="nav-item" onClick={() => setView('Login')}>
					<div className={'nav-link ' + (view === 'Login' ? 'active' : null)}>Login</div>
				</li>
				<li className="nav-item" onClick={() => setView('Register')}>
					<div className={'nav-link ' + (view === 'Register' ? 'active' : null)}>Register</div>
				</li>
			</ul>
			{view === 'Login' ? <LoginForm /> : <RegisterForm />}
		</div>
	);
};

export default LoginPage;
