import moment from "moment";

const initialState = {
    auth: {
        isAuthenticated: false,
        username: null,
        fname: null,
        lname: null
    },
    common: {
        currentView: "",
        isLoading: true,
        alertOn: false,
        alertMsg: "",
        alertType: "success"
    },
    records: {
        latestActivity: [],
        data: [],
        currentMonth: moment(),
        loaded: false,
        largestTotalCat: 0,
        largestTotalIn: 0,
        groupedCat: [],
        groupedType: [],
        groupedDate: [],
        groupedFromNow: [],
        expense: 0,
        income: 0
    },
    category: {
        data: [],
        loaded: false
    }
};

export default initialState;
