import React, { useEffect, useState } from 'react';
import { useStateValue } from '../state';
import { getCategories, deleteCategory } from '../actions/category';
import CategoryForm from '../components/CategoryForm';
import SettingsList from '../components/SettingsList';
import CategoryTiles from '../components/CategoryTiles';

const SettingsPage = () => {
	const [ { category }, dispatch ] = useStateValue();
	const [ view, setView ] = useState('Categories');
	const [ selectedCat, setSelectedCat ] = useState(null);
	const [ clearSelection, setClearSelection ] = useState(false);

	const setVariables = (cat) => {
		setSelectedCat(cat);
	};

	useEffect(
		() => {
			dispatch({
				type: 'SET_VIEW',
				payload: 'Settings'
			});
			if (!category.loaded) {
				getCategories(dispatch);
			}
		},
		[ dispatch, category ]
	);

	const clearSelected = (e) => {
		e.preventDefault();
		setSelectedCat(null);
		setClearSelection(!clearSelection);
	};

	const removeCategory = (e) => {
		e.preventDefault();
		deleteCategory(selectedCat._id, dispatch);
		setSelectedCat(null);
		setClearSelection(!clearSelection);
	};

	return (
		<div className="container page-container">
			<ul className="nav nav-pills nav-fill d-flex justify-content-around">
				<li className="nav-item" onClick={() => setView('Categories')}>
					<div className={'nav-link ' + (view === 'Categories' ? 'active' : null)}>Categories</div>
				</li>
				<li className="nav-item" onClick={() => setView('List')}>
					<div className={'nav-link ' + (view === 'List' ? 'active' : null)}>List</div>
				</li>
			</ul>
			{view === 'Categories' && (
				<React.Fragment>
					<CategoryForm />
					<CategoryTiles
						categories={category.data}
						hasDefault={false}
						showType={true}
						setVariables={setVariables}
						clearSelection={clearSelection}
					/>
					{selectedCat && (
						<div className="row">
							<div className="col-6">
								<button onClick={(e) => removeCategory(e)} className="btn btn-danger btn-block">
									Delete
								</button>
							</div>
							<div className="col-6">
								<button onClick={(e) => clearSelected(e)} className="btn btn-secondary btn-block">
									Cancel
								</button>
							</div>
						</div>
					)}
				</React.Fragment>
			)}
			{view === 'List' && <SettingsList />}
		</div>
	);
};

export default SettingsPage;
