import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../state";

import { getRecords } from "../actions/record";
import { getCategories } from "../actions/category";

import RecentTransaction from "../components/RecentTransaction";

const DataPage = () => {
    const [{ records, category }, dispatch] = useStateValue();
    const [view, setView] = useState("Recent");
    useEffect(() => {
        dispatch({
            type: "SET_VIEW",
            payload: "Data"
        });
        dispatch({
            type: "SET_CURRENT_MONTH",
            payload: moment()
        });
        if (!records.loaded) {
            getRecords(moment().format("MM-YYYY"), dispatch);
        } else {
            dispatch({
                type: "SET_FROMNOW_DATA"
            });
        }
        if (!category.loaded) {
            getCategories(dispatch);
        }
    }, [dispatch, records.loaded, category.loaded]);
    return (
        <div className="container page-container">
            <ul className="nav nav-pills d-flex justify-content-around">
                <li className="nav-item" onClick={() => setView("Recent")}>
                    <div
                        className={
                            "nav-link " + (view === "Recent" ? "active" : null)
                        }
                    >
                        Recent Transactions
                    </div>
                </li>
                <li className="nav-item" onClick={() => setView("List")}>
                    <div
                        className={
                            "nav-link " + (view === "List" ? "active" : null)
                        }
                    >
                        List
                    </div>
                </li>
            </ul>
            {view === "Recent" && (
                <RecentTransaction records={records} category={category} />
            )}
        </div>
    );
};

export default DataPage;
