import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingsList = () => {
	const icons = [
		'faHeartbeat',
		'faArrowCircleDown',
		'faArrowCircleUp',
		'faAppleAlt',
		'faLightbulb',
		'faHome',
		'faMoneyBill',
		'faGasPump',
		'faShoppingBasket',
		'faUtensils',
		'faBaby',
		'faCar',
		'faAlignJustify',
		'faPlane',
		'faDesktop',
		'faGamepad',
		'faMoneyCheckAlt',
		'faClinicMedical',
		'faParking',
		'faChartPie',
		'faSave',
		'faArchive',
		'faBookmark',
		'faCreditCard',
		'faCogs',
		'faSignOutAlt',
		'faWindowClose',
		'faCalendarAlt',
		'faShoppingCart',
		'faBeer',
		'faBreadSlice',
		'faDatabase'
	];

	const colors = {
		blue: '#007bff',
		purple: '#6f42c1',
		pink: '#e83e8c',
		red: '#dc3545',
		orange: '#fd7e14',
		yellow: '#ffc107',
		green: '#28a745',
		teal: '#20c997',
		cyan: '#17a2b8',
		magenta: 'magenta',
		brown: 'brown',
		dodgerblue: 'dodgerblue',
		limegreen: 'limegreen',
		salmon: '#fa8072',
		navy: '#000080',
		olive: '#808000',
		maroon: '#800000',
		babyblue: '#95b9c7',
		aquamarine: '#7fffd4',
		cream: '#ffffcc',
		neonpink: '#f535aa',
		indigo: '#4b0082',
		crimson: '#e238ec',
		white: '#fff',
		black: '#000',
		gray: '#6c757d',
		'gray-dark': '#343a40',
		'gray-light': '#ccc'
	};

	const getLibIcon = (faName) => {
		const stringSplit = faName.replace('fa', '').match(/[A-Z][a-z]+/g);
		if (stringSplit.length === 1) {
			return stringSplit[0].toLowerCase();
		} else {
			return stringSplit.reduce((string, truncated) => {
				return string.toLowerCase() + '-' + truncated.toLowerCase();
			});
		}
	};

	return (
		<div className="container pb-5">
			<div className="row">
				<div className="col-12">
					<span className="text-2 font-gray">Icons</span>
				</div>
			</div>
			{icons.map((icon) => (
				<div className="row" key={icon}>
					<div className="col-12">
						<FontAwesomeIcon className="mr-2" icon={getLibIcon(icon)} />
						{getLibIcon(icon)}
					</div>
				</div>
			))}
			<div className="row mt-2">
				<div className="col-12">
					<span className="text-2 font-gray">Colors</span>
				</div>
			</div>
			{Object.entries(colors).map(([ key, value ]) => (
				<div className="row" key={key}>
					<div className={`col-12 font-${key}`}>{key}</div>
				</div>
			))}
		</div>
	);
};

export default SettingsList;
