const categoryReducer = (state, action) => {
	switch (action.type) {
		case 'CATEGORY_LOADED':
			return {
				...state,
				data: action.payload,
				loaded: true
			};
		case 'CATEGORY_ADDED':
			return {
				...state,
				data: [ ...state.data, action.payload ]
			};
		case 'DELETE_CATEGORY':
			return {
				...state,
				data: state.data.filter((cat) => {
					return cat._id !== action.payload;
				})
			};
		default:
			return state;
	}
};

export default categoryReducer;
