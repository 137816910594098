import {
    groupByElementSum,
    reduceTotals,
    groupByElementElement
} from "../common/HelperFunc";
import moment from "moment";

const recordsReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                loaded: false
            };
        case "DATA_LOADED":
            const groupedCat = groupByElementSum(
                action.payload,
                "category",
                "amount",
                ["catType"]
            );
            const latestActivity = action.payload
                .sort((a, b) => b.createdDate - a.createdDate)
                .slice(0, 3);
            let max = 0;
            let maxIn = 0;
            groupedCat.forEach(cat => {
                if (cat.total > max && cat.catType === "Expense") {
                    max = cat.total;
                }
                if (cat.total > maxIn && cat.catType === "Income") {
                    maxIn = cat.total;
                }
            });
            return {
                ...state,
                data: action.payload,
                loaded: true,
                groupedCat,
                groupedType: groupByElementSum(
                    action.payload,
                    "catType",
                    "amount"
                ),
                groupedDate: groupByElementElement(action.payload, "day", [
                    "dateFormatted"
                ]),
                largestTotalCat: max,
                largestTotalIn: maxIn,
                expense: reduceTotals(action.payload, "catType", "Expense"),
                income: reduceTotals(action.payload, "catType", "Income"),
                latestActivity
            };
        case "ADD_RECORD": {
            const data = [...state.data, action.payload].sort(
                (a, b) => b.date - a.date
            );
            const groupedDate = groupByElementElement(data, "day", [
                "dateFormatted"
            ]).sort((a, b) => b.dateFormatted - a.dateFormatted);
            const groupedType = groupByElementSum(data, "catType", "amount");
            const groupedCat = groupByElementSum(data, "category", "amount", [
                "catType"
            ]);
            let max = 0;
            let maxIn = 0;
            let latestActivity = [];
            if (state.latestActivity.length === 3) {
                latestActivity = state.latestActivity.slice(0, -1);
                latestActivity.unshift(action.payload);
            } else {
                latestActivity = [action.payload, ...state.latestActivity];
            }
            groupedCat.forEach(cat => {
                if (cat.total > max && cat.catType === "Expense") {
                    max = cat.total;
                }
                if (cat.total > maxIn && cat.catType === "Income") {
                    maxIn = cat.total;
                }
            });
            return {
                ...state,
                data,
                groupedCat,
                groupedType,
                groupedDate,
                largestTotalCat: max,
                largestTotalIn: maxIn,
                expense: reduceTotals(data, "catType", "Expense"),
                income: reduceTotals(data, "catType", "Income"),
                latestActivity
            };
        }
        case "SET_CURRENT_MONTH":
            return {
                ...state,
                currentMonth: action.payload
            };
        case "SET_FROMNOW_DATA":
            const newData = state.data
                .sort((a, b) => b.dateFormatted - a.dateFormatted)
                .map(data => {
                    var fromNow = moment(data.date).fromNow();
                    data.fromNow = moment(data.date).calendar(null, {
                        // when the date is closer, specify custom values
                        lastWeek: "[Last] dddd",
                        lastDay: "[Yesterday]",
                        sameDay: "[Today]",
                        // when the date is further away, use from-now functionality
                        sameElse: function() {
                            return "[" + fromNow + "]";
                        }
                    });
                    return data;
                });
            const groupedFromNow = groupByElementElement(newData, "fromNow");
            return {
                ...state,
                groupedFromNow
            };
        default:
            return state;
    }
};

export default recordsReducer;
