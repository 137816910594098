import React, { useState } from 'react';
import { useStateValue } from '../state';
import { login } from '../actions/auth';

const LoginForm = () => {
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ , dispatch ] = useStateValue();

	const submitLogin = (e) => {
		e.preventDefault();
		if (username.length > 1 && password.length > 1) {
			login({ username, password }, dispatch);
		}
	};

	return (
		<div className="row mt-5">
			<div className="col-8 offset-2">
				<form onSubmit={submitLogin}>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							id="username"
							placeholder="Enter username"
							value={username}
							required
							onChange={(e) => setUsername(e.target.value)}
						/>
						<small className="font-gray float-right">Username</small>
					</div>
					<div className="form-group">
						<input
							type="password"
							className="form-control"
							id="password"
							placeholder="Enter password"
							value={password}
							required
							onChange={(e) => setPassword(e.target.value)}
						/>
						<small className="font-gray float-right">Password</small>
					</div>
					<button type="submit" className="btn btn-success">
						Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
