import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../common/HelperFunc';

const MainStat = ({ month, records }) => {
	return (
		<React.Fragment>
			<div className="row pt-4 pb-3">
				<div className="col-12 d-flex flex-column text-center">
					<div className="main-stat__label font-gray">Current Month Net</div>
					<div className="main-stat__value text-2">PHP{formatMoney(records.income - records.expense)}</div>
					<div className="main-state__month">{month.format('MMMM YYYY')}</div>
				</div>
			</div>
			<div className="row pb-2 mb-4">
				<div className="col-6">
					<div className="stats-container d-flex justify-content-center">
						<div className="stats-container__icon text-2 mx-2">
							<div className="pt-2 font-green">
								<FontAwesomeIcon icon="arrow-circle-down" />
							</div>
						</div>
						<div className="stats-container__value d-flex flex-column mx-2">
							<span className="font-gray-light">Income</span>
							<span className="text-1-5">{formatMoney(records.income)}</span>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className="stats-container d-flex justify-content-center">
						<div className="stats-container__icon text-2 mx-2">
							<div className="pt-2 font-red">
								<FontAwesomeIcon icon="arrow-circle-up" />
							</div>
						</div>
						<div className="stats-container__value d-flex flex-column mx-2">
							<span className="font-gray-light">Expense</span>
							<span className="text-1-5">{formatMoney(records.expense)}</span>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default MainStat;
